<template>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ chart.title }}</h4>
        </template>
        <template v-slot:headerAction>

            <ul class="nav nav-pills">
                <li v-for="(item, index) in tabsPeriod" :key="index" class="nav-item">
                    <a href="#" class="nav-link" :class="item.default ? 'active' : ''"
                        @click="getStatsByPeriod($event, item.code)">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </template>
        <template v-slot:body>
            <div class="libolo">
                <b-row class="mt-2">
                    <b-col md="12">
                        <b-row class="d-flex justify-content-center">
                            <b-col v-if="showDates" md="3" class="px-2">
                                <b-form-group label="From" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input id="from" class="px-1" type="date" v-model="form_dates.from"
                                        :max="form_dates.to" :value="form_dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col v-if="showDates" md="3" class="px-2">
                                <b-form-group label="To" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input id="to" class="px-1" type="date" v-model="form_dates.to"
                                        :min="form_dates.from" :max="form_dates.max" :value="form_dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col v-if="isAdmin" md="5" class="px-2">
                                <b-form-group label="Select Organization" label-for="organization">
                                    <AccountsMultiselect @onComplete="onAccountsLoaded" @onSelect="onSelectAccount" />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" class="px-2">
                                <b-form-group label="To" label-for="submit">
                                    <!-- @click="onSubmit" -->
                                    <button type="button" class="btn action btn-primary px-md-2" @click="getStats($event)"
                                        :disabled="submitload">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        <i v-else title="Submit" class="fa fa-paper-plane m-0"></i>
                                    </button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div class="libolo">
                
            </div>

            <div class="dashboard">
                <b-spinner small type="grow" v-if="loader"></b-spinner>
                <div v-else class="d-flex justify-content-center mt-4">
                    <b-button-toolbar v-for="(item, index) in indicators" :key="index" aria-label="">
                        <b-button-group size="sm" class="mr-0">
                            <b-button @click="toggleChartSerie($event, item, index)"
                                :style="{ 'background-color': chart.bodyData.colors[index], 'border-color': chart.bodyData.colors[index] }"
                                :class="chart.columnsHide.includes(item.title) ? 'indicator-hide' : '.indicator-show'">

                                {{ item.title }}
                            </b-button>

                        </b-button-group>
                        <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3" :class="chart.columnsHide.includes(item.title) ? 'indicator-hide' : '.indicator-show'">
                        </b-input-group>
                    </b-button-toolbar>
                </div>

                <div v-if="!loader">
                    <ApexChart v-if="!loader" :element="slug" :chartOption="chart.bodyData"
                        :columnsHide="chart.columnsHide" />
                </div>
            </div>
        </template>
    </iq-card>
</template>

<script>

const EVENTS = 5;
const REVENUE_STATS = 6;

const DAY_PERIOD = 0;
const WEEK_PERIOD = 1;
const MONTH_PERIOD = 2;
const YEAR_PERIOD = 3;

import _ from 'lodash'
import moment from 'moment'
import store from '@/store/index'
import Multiselect from 'vue-multiselect'
import AccountsMultiselect from '@/views/children/AccountsMultiselect.vue'
import api from '@/api/RestClient'
import { helper } from '@/helpers'

export default {
    name: 'CustomKpis',
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    components: {
        Multiselect,
        AccountsMultiselect
    },
    mounted() {
        this.setOption();
        this.initIntervalDates();
        this.getStats();
    },
    data() {
        return {
            isAdmin: store.getters['Auth/isAdmin'],
            chart: {
                title: '***',
                type: 'line-area',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'area',
                        stacked: false,
                        events: {
                             legendClick: (chartContext, seriesIndex, config) => {
                                 this.clearHideColumns(chartContext, seriesIndex)
                             }
                         }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [3, 3, 3, 3, 3, 3, 3, 3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                      forceNiceScale: true,
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                      onItemClick: {
                        toggleDataSeries: true, // Toggle the visibility of the series
                      },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    },
                },
                columnsHide: [],
            },
            indicators: [],
            title: '***',
            slug: null,
            loader: true,
            labels: [],
            tabsPeriod: [
                { code: DAY_PERIOD, name: 'Days', format: 'YYYY-MM-DD', offset: 20 },
                { code: WEEK_PERIOD, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: MONTH_PERIOD, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: YEAR_PERIOD, name: 'Years', format: 'YYYY', offset: 1 },
            ],
            form_dates: {
                from: null,
                to: null,
                max: null,
            },
            periodCode: -1,
            option: null,
            statsData: null,
            organization: null,
            organizations: [],
            showDates: false,
            submitload: false,
        }
    },
    methods: {
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        loadSeries() {

            if (this.statsData) {
                this.statsData.series.forEach(serie => {
                    this.chart.bodyData.series.push(serie)
                });
            }
        },
        loadIndicators() {
            if (this.statsData) {
                this.indicators = this.statsData.indicators;
            }
        },
        setTitle() {
            if (this.option) {
                this.chart.title = this.option.title;
            }
        },
        setXaxisType() {
            if (this.option) {
                this.chart.bodyData.xaxis = {
                    type: this.option.axis_type
                }
            }
        },
        chartElement() {
            if (this.option) {
                this.slug = this.option.element;

            }
        },
        setLabel() {
            if (this.statsData) {
                this.chart.bodyData.labels = this.statsData.labels;
            }
        },
        setOption() {
            const that = this
            let activePeriod = _.find(that.tabsPeriod, function (o) { return that.options.period == o.code; });
            if (activePeriod) {
                activePeriod.default = true
            }

            if (this.options) {
                this.option = this.options
            }
            if (typeof this.options.exceptPeriod !== "undefined") {
                this.tabsPeriod = _.filter(that.tabsPeriod, function (o) { return !that.options.exceptPeriod.includes(o.code); });
            }
            this.showDates = !that.options.exceptDatesFor.includes(that.options.period) ? true : false
        },
        setPeriodCode(val = 0) {
            this.periodCode = val;
        },
        getStatsByPeriod(event, period = 0) {
            event.preventDefault()

            let exceptDatesFor = [];
            if (typeof this.options.exceptDatesFor !== "undefined") {
                exceptDatesFor = this.options.exceptDatesFor
            }

            this.showDates = !exceptDatesFor.includes(period) ? true : false

            let elements = this.$el.querySelectorAll('a.nav-link')
            elements.forEach((noeud) => {
                noeud.classList.remove('active')
            });
            event.target.classList.add('active')
            this.setPeriodCode(period)
        },
        getStats() {
            this.loader = true;
            this.resetData();

            const period = this.option.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            let format = currentPeriod ? currentPeriod.format : 'YYYY-MM-DD';
            let comparator = currentPeriod ? this.getPeriodNameSingular(currentPeriod.name) : 'day';

            this.getChartData()
                .then(response => {

                    this.statsData = this.getChartDataFormatted(response, format, comparator)

                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setTimeout(() => { this.loader = false; }, 2000)
                });
        },
        getPeriodNameSingular(str, singular = true) {
            if (typeof str == "undefined") return null;

            if (str.charAt(str.length - 1) == 's') str = str.slice(0, str.length - 1);
            return singular ? str.toLowerCase() : str;
        },
        getChartDataFormatted(response, format, comparator) {
            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const that = this
            const data = response.data;

            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            data.items.forEach(item => {
                let graphItems = _.filter(data.data, function (o) {

                    let label = null;
                    if (typeof that.options.itemLabel !== "undefined") {
                        label = (o.hasOwnProperty(that.options.itemLabel))
                            ? that.options.itemLabel : null
                    }

                    return item.id == o[label];
                });


                let indicatorPlan = {
                    title: item.name,
                    indicator: `${that.option.indicator || ''}${ item.id == 'sends_rate' ? this.getRate(data.data) : ( item.id == 'reception_rate' || item.id == 'tracked_contacts' ) ? this.getAverge(data.data, item.id) :this.getSumItemsByAttr(graphItems)}`,
                    disabled: false
                }
                if ((typeof item.disabled !== 'undefined' && item.disabled)) {
                    let alreadyHide = _.find(that.chart.columnsHide, function (o) { return indicatorPlan.title == o; });

                    if (!alreadyHide) {
                        this.chart.columnsHide.push(indicatorPlan.title)
                    }
                }

                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            // Update X tooltip
            this.chart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            _.uniq(this.chart.columnsHide)
            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                data.push(this.getSumItemsByAttr(graphItems))
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {


            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr)) {
                    return accumulator + 0;
                }
                return accumulator + parseInt(object[attr]);
            }, 0);
            return sum.toFixed(0);
        },
       getAverge(items = [],key) {

        let total = 0;

        let number = 0;
        items.forEach( (currentElement) => {


          if(currentElement.event_id && currentElement.event_id == key && currentElement.total>0) {


            total +=  parseInt(currentElement.total);
            number++;

          }

        });

        let ratio =  number != 0 ? total / number : 0;


        return Math.round(ratio);
      },
        getRate(items = []) {
            let total_received = 0;
            let total_sent = 0;
            let total_rate = 0;
            let ratio = 0;
            items.forEach(function (currentElement) {
                total_received += currentElement.event_id == "received_events" ? parseInt(currentElement.total) : 0;
                total_sent += currentElement.event_id == "events_sent" ? parseInt(currentElement.total) : 0;
            });
            
            ratio = total_received != 0 ? total_sent / total_received : 0;
            total_rate = Math.round(100 * ratio);

            return total_rate;
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getChartData() {
            const type = this.option.key ? '&type=' + this.option.key : '';
            const account = this.organization ? '&account=' + this.organization.code : '';
            const periodUri = `from=${this.form_dates.from}&to=${this.form_dates.to}`

            const link = `${this.option.url}/${this.option.period}?${periodUri}${account}${type}`
            return api.dashboard.statistics(link);
        },
        resetData() {
            this.chart.bodyData.series = [];
            this.chart.bodyData.labels = [];
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },
        initIntervalDates() {
            let date = moment();
            let minDate = date.clone();

            const period = this.option.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.form_dates.to = date.format('YYYY-MM-DD');
            this.form_dates.from = minDate.format('YYYY-MM-DD');
            this.form_dates.max = date.format('YYYY-MM-DD');
        },
        onAccountsLoaded(data) {
            const that = this;
            setTimeout(() => {
                // Do something
            }, 500);
        },
        onSelectAccount(accountSelected) {
            if (store.getters['Auth/isAdmin']) {
                if (accountSelected) {
                    this.organization = accountSelected
                } else {
                    this.organization = null
                }
            }
        },
        toggleChartSerie($event, item, index) {
            $event.preventDefault();

            // Fix on LiveLeads Events Chart
            if (this.options.key == 7) {
                let serie = item.title;

                if (serie.toLowerCase() == 'leads') {
                    serie = 'Total Events';
                }

                this.detailsRedirect(this.form_dates.from, this.form_dates.to, item.title);
            }

        },
        clearHideColumns(chartContext, index) {
            let currIndicator = this.indicators[index];
            const that = this;
            if (_.includes(this.chart.columnsHide, currIndicator.title)) {
                this.chart.columnsHide = _.remove(this.chart.columnsHide, function (popItem) {
                    return popItem !== currIndicator.title;
                });
            } else {
                let alreadyHide = _.find(that.chart.columnsHide, function (o) { return currIndicator.title == o; });

                if (!alreadyHide) {
                    this.chart.columnsHide.push(currIndicator.title)
                }
            }
        },
        isHide(name) {
            return _.includes(this.chart.columnsHide, name)
        },
        detailsRedirect(dateFrom = null, dateTo = null, serie = null) {
            helper.redirectToLiveLeadsRecords(this, dateFrom, dateTo, serie)
        },
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.setXaxisType();
                this.loadSeries();
                this.loadIndicators();
                this.setTitle();
                this.chartElement();
                this.setLabel();
            }
        },
        'periodCode': {
            handler(newValue, oldValue) {
                this.option.period = newValue;
                this.resetData();
                this.initIntervalDates();
                this.getStats();
            }
        },
        'organization': {
            handler(newValue, oldValue) {
                this.resetData();
                this.getStats();
            }
        }
    },
}
</script>

<style>
.dashboard {
    text-align: center;
    margin-bottom: 5px;
}

.libolo {
    width: 50%;
    margin: auto;
}

.indicator-hide {
    display: none;
}

.indicator-show {
    display: inline;
}

@media screen and (max-width: 600px) {
    .price-week-box {
        margin-right: 0.5rem !important;
    }

    .libolo {
        width: 90% !important;
    }
}
</style>