<template>
    <b-container fluid>


        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table ref="table" :id="id" striped bordered :busy="isBusy" :items="getData" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty
            small @filtered="onFiltered" @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell type -->
            <template #cell(type)="row">
                <div class="badge"
                    :class="{ 'badge-info': row.item.type == 'amount', 'badge-primary': row.item.type == 'percentage' }">
                    {{ row.item.type }}
                </div>
            </template>

            <!-- Cell usage_limit -->
            <template #cell(usage_limit)="row">
                <div
                    :class="{ 'badge badge-secondary': row.item.usage_limit == -1, 'badge badge-danger': row.item.usage_limit == 0 }">
                    {{ row.item.usage_limit == -1 ? 'Unlimited' : row.item.usage_limit }}
                </div>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span class="badge cursor-pointer badge-secondary badge-pill"
                    :class="{ 'iq-bg-success': row.item.status > 0, 'iq-bg-danger': row.item.status == -1, 'iq-bg-secondary': row.item.status == 0 }">
                    {{ row.item.status == -1 ? 'Delete' : (row.item.status > 0 ? 'Active' : 'Inactive') }}
                </span>
            </template>

            <!-- Actions -->
            <template #cell(action)="row">
                <div class="text-center d-flex user-flex">


                  <button type="button" class="btn iq-bg-info mr-1 px-2 btn-sm"
                          v-b-tooltip v-b-tooltip.hover title="Copy Link"
                          @click="copyToClipBoard(row.item)">
                    <i class="ri-file-copy-line"></i>
                  </button>

                  <button v-if="row.item.status != -1" type="button" class="btn iq-bg-primary mr-1 px-2 btn-sm"
                            v-b-tooltip v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Show commissions" data-original-title="Test"
                            @click="commissions(row.item)">
                            <i class="fa fa-eye mx-0" v-show="true"></i>
                        </button>
                    <button v-if="row.item.status != -1" type="button" class="btn iq-bg-info mr-1 px-2 btn-sm"
                        :id="`btn-copy-${row.item.id}`" :ref="`btn-copy-${row.item.id}`" v-b-tooltip v-b-tooltip.hover
                        title="Add pay commission" @click="addCommission(row.item)">
                        <i class="fa fa-dollar"></i>
                    </button>
                    <button v-if="row.item.status != -1" type="button" class="btn iq-bg-primary mr-1 px-2 btn-sm"
                        v-b-tooltip v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Test"
                        @click="edit(row.item)">
                        <i class="fa fa-edit mx-0" v-show="true"></i>
                    </button>
                    <button v-if="row.item.status !== -1" type="button" class="btn iq-bg-danger mr-1 px-2 btn-sm"
                        v-b-tooltip v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Delete" data-original-title="Test"
                        @click="remove(row.item)">
                        <i class="fa fa-trash mx-0" v-show="true"></i>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- DataTable pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status"
                        aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'

export default {
    name: 'DtDiscounts',
    components: {
        Spinner1,
    },
    data() {
        return {
            id: 'dt-discounts',
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'id',
                    label: '#ID',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return "#" + item.id
                    },
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-left'
                },
                {
                    key: 'discount_id',
                    label: 'Discount',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const discount = item.discount;
                        return discount ?.name ?? '--';
                    }
                },
                {
                    key: 'xxx',
                    label: 'Discount value',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                         const discount = item.discount;
                        return discount ? (discount.type == 'percentage' ? `${discount.value}%` : `$${discount.value}`) : 0
                    }
                },
                {
                    key: 'commission',
                    label: 'Commission value',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return  `${item.commission}%`
                    }
                },
                {
                    key: 'referral',
                    label: '#Referrals',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return `${item.accounts.length}`
                    }
                },
                {
                    key: 'unpaid',
                    label: 'Unpaid',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                       let total = this.total(item.accounts);
                        let total_due = Math.round((total * item.commission) / 100, 2)
                        let total_paid = this.calculateSum(item.commissions, 'total');
                        total = total_due - total_paid;
                        return helper.moneyDefaultFormat(total);
                    }
                },
                                {
                    key: 'paid',
                    label: 'Paid',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                         let total = this.calculateSum(item.commissions, 'total');
                        return helper.moneyDefaultFormat(total);
                    }
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'created_at',
                    label: 'Creation',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        var dt = new Date(item.created_at);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false,
                    class: 'text-center'
                },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'created_at',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name"],
            account: -1,
            dates: null,
            $search: '',
            from: null,
            to: null,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
        this.loadFields()
    },
    methods: {
      copyToClipBoard(item){
        let link = process.env.VUE_APP_URL + '/signup?via=' + item.code;

        navigator.clipboard.writeText(link)
            .then(() => {
              this.$bvToast.toast('Affiliate link successfully copied.', {
                title: 'Success',
                variant: 'success'
              });
            })
            .catch(err => {
              console.error('Failed to copy text:', err);
            });
      },
        calculateSum(array, property) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);

            return total;
        },
        total(data) {
            let total = 0;
            if (data.length > 0) {
                let accounts = data;
                accounts.forEach(account => {
                    if (account.revenue_stats.length > 0) {
                        let revenue_stats = account.revenue_stats;
                        revenue_stats.forEach(revenue_stat => {
                            total += revenue_stat.revenue;
                        });
                    }
                });
            }

            return `${total}`
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
        this.$emit('onUpdatedTable');
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                }
            }

            return api.referrals.get(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$emit('updatedTable');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (!["account"].includes(item.key)) return item;
            })
            this.fields = fields;
        },

        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        edit(item) {
            this.$emit('handleEdit', item)
        },
        commissions(item) {
            this.$emit('onShowCommissions', item)
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.referrals.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete referral', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        addCommission(item) {
            this.$emit('handleCommission', item);
        },
        toast(title = "success", variant = "success", message = "Discount added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.user-flex {
    justify-content: center;
}
</style>
