<template>
<b-form @submit="onFilter">
    <b-row class="mt-2">
        <b-col md="12" lg="12">
            <h4>Filters</h4>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Choose platform:" label-for="input-3">
                        <multiselect 
                        :loading="isLoading" 
                        :disabled="isLoading" 
                        tag-placeholder="Manage platform" 
                        placeholder="Choose platform" 
                        label="name" 
                        track-by="code" 
                        v-model="form.platform" 
                        deselect-label="Can't remove this value" 
                        :allow-empty="true" 
                        :options="platforms" 
                        :multiple="true"
                        :taggable="true">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group id="input-group-platform_account" label="Platform account:" label-for="input-group-platform_account">
                        <b-form-input v-model="form.platform_account" type="text" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Platform Account"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Create at date from:" label-for="input-3">
                        <b-form-input :max="form.date_to" v-model="form.date_from" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date from"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Created at date to:" label-for="input-3">
                        <b-form-input :min="form.date_from" v-model="form.date_to" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date to"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4" class="text-left">
                    <b-form-group id="label" label="Status:" label-for="input-3">
                        <multiselect 
                        tag-placeholder="Manage status" 
                        placeholder="Choose status" 
                        label="name" 
                        track-by="code" 
                        v-model="form.status" 
                        deselect-label="Can't remove this value" 
                        :allow-empty="true" 
                        :options="statusList" 
                        :multiple="true" 
                        :taggable="false">
                        </multiselect>
                    </b-form-group>
                </b-col>
                 <b-col md="4" class="text-left">
                        <b-form-group id="label" label="Auto Refresh:" label-for="input-3">
                            <multiselect tag-placeholder="Manage auto refresh" placeholder="Choose status" label="name" track-by="code" v-model="form.auto_refresh" deselect-label="Can't remove this value" :allow-empty="true" :options="refreshStates" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                <b-col md="4" class="text-right">
                    <label id="label-btn" class="sr-only" for="inline-form-input-name">Name</label>
                    <b-button type="submit" :disabled="onFilterDisable" variant="primary" style="height: 3.3em; margin-top: 2.3em">
                        <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                    </b-button>
                </b-col>

            </b-row>
        </b-col>

    </b-row>
</b-form>
</template>

<script>
import api from '@/api/RestClient'
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import { helper } from '@/helpers';
import {SERVICES} from "@/constantes";
import { mapGetters } from 'vuex'

export default {
    name: 'FilterList',
    props: {
        onFilterTable: {
            type: Boolean,
            default: false
        },
        platforms_array: {
            type: Array,
            default: () => []
        },
        account: {
            type: Object,
            default: null,
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        sofbox.index()
        this.fetchPlatforms();
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                status: false,
                daily_cap: null,
                platform: null,
                date_to: null,
                date_from: null,
                auto_refresh:null,
                platform_account:null
            },
            platforms: [],
            status: [{
                    code: 1,
                    name: 'active'
                },
                {
                    code: 0,
                    name: 'inactive'
                }

            ],
            statusList: [
                 {
                    code: [-1, -2],
                    name: 'Inactive/Deleted'
                },
                {
                    code: -3,
                    name: 'disconnected'
                },
                {
                    code: 0,
                    name: 'In progress'
                    },
                {
                    code: 1,
                    name: 'Synced'
                },
            ],
            refreshStates: [
                 {
                    code: 1,
                    name: 'Active'
                },
                {
                    code: 0,
                    name: 'Inactive'
                }
            ]
        }
    },
    methods: {
        platformName(name) {
            return helper.platformFormatName(name);
        },
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form)
        },
        fetchPlatforms() {
            var data = JSON.parse(JSON.stringify(this.platforms_array))
            data.forEach((plat) => {
                let platform = {};
                platform.name = this.platformName(plat.name);
                platform.code = plat.id;
                this.platforms.push(platform);
            });
            this.isLoading = false;
        },
        finishFilter() {
            this.onFilterDisable = false
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccountManager: 'Auth/isAccountManager',
        }),
    }
}
</script>
<style>
    .form-border{
        border: 5px solid;
        border-radius: 5px;
        margin-bottom: 30px;
        padding: 10px;
    }
</style>
