<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Integrations</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="3" class="my-1">
                <b-input-group size="md">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Search"
                        @input="onSearch"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col lg="8" class="my-1" v-show="false">
                <Export :data="exportOptions"></Export>
            </b-col>

        </b-row>

      <Transition>

          <div v-show="selected.length || selectAllLoading" class="alert alert-info my-0 p-3 " style="background-color: #a5e2ff26;color: #097693;vertical-align: middle;align-self: center">
            <div class="iq-alert-icon">
              <i class="ri-information-line"></i>
            </div>
            <div class="iq-alert-text d-flex" style="align-self: center;align-items: center;justify-content: space-between">
                            <span>
                                {{ (selected.length == to && selected.length > 1) ? 'All' : '' }} <b>{{ selected.length}}</b>
                                Integration{{ selected.length > 1 ? 's' : '' }}
                                {{ selected.length < totalRows ? 'on this page' : 'recorded' }} are selected. </span>

              <div>
              <button class="btn btn-sm btn-info py-2 px-3 mx-2" v-if="selected.length < totalRows" style="background-color: #4c8eb1;border-color: #628daf;"
                        @click="selectAllItems">
                Select all <b>{{ totalRows }}</b> Integrations
              </button>
              <button v-else-if="selected.length == totalRows" class="btn btn-sm btn-info py-2 px-3 mx-2" style="background-color: #777;border-color: #777;"
                        @click="clearSelection">
                Clear Selection
              </button>


              <b-dropdown dropright variant='none' no-caret class="float-right">
                <template #button-content>
                  <!-- Group actions -->

                  <button v-b-tooltip.hover
                          :title="`Grouped action on` + (selected.length ? ` ${(selected.length)} Integration${(selected.length > 1 ? 's' : '')} selected` : '')"
                          aria-haspopup="true" aria-expanded="false" type="button"
                          class="btn btn-sm p-2 shadow-sm dropdown-toggle float-right"
                          style="background-color: white !important;"
                  >

                    <span><i class="ri-more-2-fill fa-lg"></i>More Actions</span>
                  </button>

                </template>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('turn-on')">
                  <i v-b-tooltip class="text-success ri-toggle-fill fa-lg m-0"></i>
                  Turn on
                </b-dropdown-item>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('turn-off')">
                  <i class="text-secondary ri-toggle-line fa-lg m-0"></i>
                  Turn off
                </b-dropdown-item>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleBulkDailyLimitAction()">
                  <i class="text-info fas fa-magic fa-lg m-0"></i>
                  Update Daily Limit
                </b-dropdown-item>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('delete')">
                  <i class="text-danger ri-delete-bin-line i-delete fa-lg m-0"></i>
                  Delete
                </b-dropdown-item>
              </b-dropdown>
              </div>
            </div>
          </div>


      </Transition>
      <div>
        <!-- Main table element -->
        <b-table class="ig-text-wrap" :ref="'dt-live-reports'" :id="id" borderless
            :responsive="true" :busy="isBusy" :items="getData" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty
            small @filtered="onFiltered" @sort-changed="sortingChanged" @row-hovered="onRowHovered">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <template #head(selected)="row">
                <b-form-checkbox id="select-all" v-model="selectAll" name="selected" :indeterminate.sync="indeterminate"
                    @input="selectRow">
                </b-form-checkbox>
            </template>

            <!-- Cell Integration name -->
            <template #cell(name)="row">
              <div class="d-flex" style="align-items: center; gap: 10px">
                <div style="width: 16%; max-width: 40px;min-width: 35px;" class="mx-2">
                  <img :src="getPlatformImage(row.item.platform?.name)" alt="">
                </div>
                <div class="text-left">
                  <p class="text-left mx-0 my-1 font-weight-bolder" style="font-size:smaller; white-space: nowrap; overflow: hidden;text-overflow: ellipsis; ">{{ row.value }}</p>

                  <small  v-b-tooltip v-b-tooltip.hover :title="row.item.platform?.name" class="text-left my-0 text-muted d-flex" style="gap: 2px;flex-wrap: wrap;">
                     <span > {{ row.item.platform?.nickname }} </span>
                  </small>

                  <div>
                </div>
              </div>
              </div>
            </template>

          <template #cell(lists)="row">
            <div class="d-flex text-left" style="align-items: center;">

                <span
                    v-for="(list, i) in limitedValues(row.item.lists)"
                    v-b-tooltip v-b-tooltip.hover
                    :title="list.list_name.length > 15 ? list.list_name : ''"
                    class="badge badge-sm badge-light mr-1 border shadow-sm"
                    style="background-color: white !important;"
                >
                          <i class="mr-2 text-info"
                             :class="{
                            'ri-list-check': list.type == 'list',
                            'ri-list-view': list.type == 'segment',
                            'ri-list-check-2': list.type == 'STATIC'

                          }"></i>

                          {{ filterLessThan(list.list_name) }}

                      </span>

              <span v-if="Object.keys(row.item.lists).length>2"
                      :id="'popover-lists-integration' +row.item.id"
                      @click="openListsPopover(row.item)"
                    class="badge badge-sm badge-light mr-1 border shadow-sm" style="background-color: white !important;height: 22px;cursor: pointer;padding-top: 4px;" >Show more</span>

            </div>


            <b-popover

                :target="'popover-lists-integration' +row.item.id"
                :ref="'popover-lists-integration' +row.item.id"
                placement="auto"
            >
              <template #title><small>Tracked lists</small></template>
              <div class="d-flex" style="flex-direction: column;gap:5px;overflow-y: scroll;max-height: 120px;width: 250px;padding: 0px 12px 0px 0px;">
                  <span
                      v-for="(list, i) in row.item.lists"
                      class="badge badge-light border-bottom w-100 text-left p-2"
                      style="background-color: white !important;"
                  >
                          <i class="mr-2 text-info"
                             :class="{
                            'ri-list-check': list.type == 'list',
                            'ri-list-view': list.type == 'segment',
                            'ri-list-check-2': list.type == 'STATIC'

                          }"></i>

                          {{ list.list_name }}
                      </span>
              </div>
            </b-popover>

          </template>

          <!-- Cell status -->
          <template #cell(tag_name)="row">
               <span v-b-tooltip v-b-tooltip.hover :title="row.value.length > 15 ? row.value : ''" class="badge badge-light badge-pill">
                    <i class="ri-bookmark-fill text-info mr-2"></i>
                    {{ filterLessThan(row.value) }}
                     </span>

          </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span class="badge cursor-pointer badge-secondary badge-pill"
                    :class="row.item.active == 1 ? 'iq-bg-success' : 'iq-bg-danger'">
                    {{ row.item.active == 1 ? 'Active' : 'Inactive' }}
                </span>
            </template>
            <!-- Cell status -->
            <template #cell(active)="row">
              <div v-if="row.item.active == -2"  @click="selectedIntegration = row.item">
                <SwitchCell
                    v-b-modal.activate-confirmation-disabled-integration-modal
                    :id="'disabled-integration' +row.item.id "
                    :row="row"

                    :variant="( row.item.enabled_at != null || row.item.disabled_at != null ) ? 'bg-info' : 'bg-primary'"
                />
              </div>
              <div v-else :id="'status-container-' + row.item.id">

                <div
                    v-b-tooltip.hover :title="scheduleToolTip(row.item)"
                    @click.prevent="openSchedulePopover(row.item)"
                    :class=" ( row.item.enabled_at != null || row.item.disabled_at != null ) ? 'scheduled' : ''">
                   <span v-if="editModal.item.id === row.item.id && loading.update"
                         class="badge badge-light mx-1 my-auto">Editing</span>
                <SwitchCell   v-else
                            :row="row"
                            :id="'popover-schedule-integration' +row.item.id "
                            :variant="( row.item.enabled_at != null || row.item.disabled_at != null ) ? 'bg-info' : 'bg-primary'" />
                </div>

                <div
                    :ref="'popover-click-outside-' + row.item.id"
                    @click="closePopover( row.item)"
                    style="background-color: rgb(105 102 114 / 10%);position: absolute;inset: -100vh -200vw -100vh -200vh;z-index: 1050;display: none;"></div>

              <b-popover
                  :target="'popover-schedule-integration' +row.item.id "
                  :ref="'popover-schedule-integration' +row.item.id "
                  placement="auto"
                  :container="'status-container-' + row.item.id"
                  :delay="{ show: 1000, hide: 950 }"
              >

                <ScheduleIntegrationModal
                    :integration="row.item"
                    @finished="finishSchedulePopover"
                    @error="handelErrorSchedulePopover"
                    @canceled="closePopover"
                    @updateIntegrationStatus="onUpdateItem(row)"
                ></ScheduleIntegrationModal>

              </b-popover>
              </div>

            </template>
            <!-- Cell status -->
            <template #cell(list_quality)="row">
                <span>
                    <span><i class="mr-1" :class="listQualityIndic(row.item)"></i>{{ listQuality(row.item) }}%</span>
                </span>
            </template>

            <!-- Cell created_at -->
            <template #cell(created_at)="row">
               <small>{{row.value}}</small>
            </template>
          <!-- Cell organization -->
          <template #cell(account_id)="row">
            <small>{{row.value}}</small>
          </template>
            <!-- Cell selected -->
            <template v-slot:cell(selected)="row">
                <div class="text-center">

                    <input type="checkbox" v-model="row.item.selected" :checked="isChecked(row.item)"
                        @input="hasChangeRowSelect($event, row.item)" />
                </div>
            </template>

            <!-- Cell limit -->
            <template v-slot:cell(limit)="row">
                <div class="">
                    <span v-if="row.value == -1" class="badge badge-light mx-1 my-auto">Unlimited</span>
                    <span v-else>{{ row.value }}</span>

                    <span v-if="row.item.lo_daily_limit_auto_update" class="badge badge-info mx-1 my-auto"
                        v-b-tooltip.hover="'Applied Logic'">
                        {{ row.item.lo_daily_limit_auto_update?.name }}
                    </span>
                </div>
            </template>

            <!-- Cell actions -->
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end" style="gap: 2px;">
                    <button @click="getChart(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;" v-b-tooltip.hover="'View Graph'">
                        <i class="fa fa-eye" v-if="row.item.state != 101"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 101" type="grow"></b-spinner>
                    </button>

                    <button @click="exportCSV(row.item, row.index, $event.target)" type="button"
                            class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;"  v-b-tooltip.hover="'Email Event Log'">
                        <i class="fa fa-download" v-if="row.item.state != 102"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 102" type="grow"></b-spinner>
                    </button>
                    <button type="button" class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;"  v-b-tooltip.hover data-toggle="tooltip"
                        title="Histories" @click="histories(row.item)">
                        <i class="fa fa-history m-0"></i>
                    </button>
                    <button @click="editItem(row, $event.target)" type="button"
                            class="btn iq-bg-primary  btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;"
                            v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Edit">
                        <i class="ion-edit m-0" title="Edit integration"></i>
                    </button>
                    <button v-if="canLinkAutomation(row.item)"
                        @click="linkAutomation(row.item, row.index, $event.target)" type="button"
                            class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;" v-b-tooltip.hover data-toggle="tooltip" data-placement="top"
                        title="Link Automation">
                        <i class="ri-link m-0" title="Link Automationn"></i>
                    </button>
                    <button v-if="canLinkAutomation(row.item) && row.item.lo_automation"
                        @click="unlinkAutomation(row.item, row.index, $event.target)" type="button"
                            class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;" v-b-tooltip.hover data-toggle="tooltip"
                        data-placement="top" title="UnLink Automation">
                        <i class="ri-link-unlink m-0" title="UnLink Automationn"></i>
                    </button>
                    <button v-if="row.item.active >= 0" @click="sendFlow(row.item, row.index, $event.target)"
                        type="button"  class="btn iq-bg-primary btn-sm border text-muted shadow-sm" style="color: #333333 !important; background-color: white !important;" v-b-tooltip.hover data-toggle="tooltip"
                        data-placement="top" title="Send test Flow">
                        <i class="fa fa-paper-plane m-0" title="Test event"></i>
                    </button>
                    <button v-if="row.item.active >= 0" @click="removeItem(row.item, row.index, $event.target)"
                        type="button"  class="btn btn-sm" style="background-color: #f45757;color: white;" v-b-tooltip.hover data-toggle="tooltip"
                        title="delete">
                        <i title="Delete" class="ri-delete-bin-line i-delete m-0 delete_integration_icone"></i>
                        <div class="spinner-grow spinner-grow-sm delete_integration_spiner d-none" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                    <button v-show="row.item.validation_code != null" type="button" class="btn pr-2 btn-sm" style="padding-left:12px;background-color: rgb(67 181 190);color: white !important;" v-b-tooltip.html data-toggle="tooltip"
                        :title="integration_logs(row.item)">
                        <i class="fa fa-info"></i>
                    </button>
                </div>
            </template>

        </b-table>
      </div>
        <!-- Data table pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status"
                        aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>

        <!-- Info modal -->
        <b-modal v-if="renderModalUpdate" :id="editModal.id" :title="editModal.title" size="lg" centered
            :ref="editModal.id" hide-footer @hide="resetEditModal">
            <ModalUpdateIntegration :item="editModal.item" :form="{}" col=12 @modalHide="hideUpdateIntegrationModalide">
            </ModalUpdateIntegration>
        </b-modal>
        <!-- Modals SendFlow -->
        <b-modal :id="sendFlowModal.id" size="md" title="Integration Test" :ref="sendFlowModal.id" hide-footer>
            <testFlow :integrationId="sendFlowModal.item.id" col=12></testFlow>
        </b-modal>

        <!-- Link Automation modal -->
        <ModalLinkAutomation :id="loAutomationModal.id" :title="loAutomationModal.title" col=12 size="xl" centered
            :ref="loAutomationModal.id" hide-footer :item="loAutomationModal.item" organization:
            loAutomationModal.item.account_id @hide="resetLoAutomationModal" @modalHide="hideModalLoAutomation">
        </ModalLinkAutomation>

        <!-- Modals Bulk Update Daily Limits -->
        <DailyLimitsModal :id="bulkDailyLimitModal.id" :ref="loAutomationModal.id"
            v-bind="{title: bulkDailyLimitModal.title, items: bulkDailyLimitModal.items}" centered hide-footer
            @modalHide="hideModalBulkDailyLimit"></DailyLimitsModal>

        <!-- Modal Confirmation Activate Disabled Integration -->
        <ActivateConfirmationDisabledIntegrationModal :integration="selectedIntegration" @IntegrationActivated ="updateDisabledIntegration"></ActivateConfirmationDisabledIntegrationModal>

    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import Export from '../children/Export'
import Columns from '../children/Columns'
import TestFlow from '@/components/inboxgeek/sendflow'
import SwitchCell from '@/components/inboxgeek/tables/SwitchCell.vue'
import ModalUpdateIntegration from '../../Integration/modals/ModalUpdateIntegration.vue'
import ModalLinkAutomation from '../../Integration/modals/ModalLinkAutomation.vue'
import DailyLimitsModal from '@/components/inboxgeek/DailyLimitsModal.vue'
import ScheduleIntegrationModal from "@/components/inboxgeek/modals/ScheduleIntegrationModal";
import ActivateConfirmationDisabledIntegrationModal from "@/components/inboxgeek/modals/ActivateConfirmationDisabledIntegrationModal";
import {
    helper
} from '@/helpers';
import {
    mapGetters
} from "vuex";
import moment from 'moment'
import IBGPlatformCell from '@/components/inboxgeek/tables/IBGPlatformCell.vue'
import _ from 'lodash'

const SERVICE_ID = 1;

export default {
    name: 'IntegrationDataTable',
    components: {
        Spinner1,
        Export,
        SwitchCell,
        TestFlow,
        ModalUpdateIntegration,
        ModalLinkAutomation,
        Columns,
        IBGPlatformCell,
        DailyLimitsModal,
      ScheduleIntegrationModal,
      ActivateConfirmationDisabledIntegrationModal
    },
    data() {
        return {
            id: 'dt-live-reports',
          selectedIntegration:null,
            hideShowMoreButton:[],
            isBusy: false,
            loading: {
                update: false
            },
            items: [],
            fields: [
                {
                    key: 'selected',
                    label: '',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'name',
                    label: 'Integration',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-left'
                },
              {
                key: 'lists',
                label: 'Lists',
                sortable: false,
                class: 'text-left'
              },
              {
                key: 'tag_name',
                label: 'Tag',
                sortable: true,
                sortDirection: 'desc',
                class: 'text-center'
              },
                {
                    key: 'account_id',
                    label: 'Organization',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center account d-none',
                    formatter: (value, key, item) => {
                        return item?.account ?.name ?? "--"
                    }
                },
                {
                    key: 'referral_id',
                    label: 'Affiliate',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        return item.account ?.referral ?.name ?? '--'
                    }
                },
                {
                    key: 'total_contacts',
                    label: 'Total Contacts',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        const lists = item.lists;
                        const contacts = lists.length > 0 ? this.sumInArrayByKey(item.lists, 'total_contacts') : 0
                        return helper.formatDecimal(contacts);
                    }
                },
                {
                    key: 'total_events',
                    label: 'Total Events',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        const totalEvents = item.events.length > 0 ? this.eventCalculStats(item.events) : 0;
                        return helper.formatDecimal(totalEvents);
                    }
                },
                {
                    key: 'limit',
                    label: 'Daily Cap',
                    sortable: true,
                    class: 'text-center d-none'
                },
                {
                    key: 'time_restrictions',
                    label: 'Time Restrictions',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        let timeRestrictionJson = JSON.parse(item.time_limit)
                        let timeRestriction = timeRestrictionJson.time_limit_to ? timeRestrictionJson.time_limit_from + ' to ' + timeRestrictionJson.time_limit_to : '--'
                        return timeRestriction;
                    }
                },
                {
                    key: 'events_cycle',
                    label: 'Event Cycle',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        const totalCycleEvents = item.cycle_events.length > 0 ? this.eventCalculStats(item.cycle_events) : 0;
                        return helper.formatDecimal(totalCycleEvents);
                    }
                },
                {
                    key: 'avg_events',
                    label: 'AVG Events',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        const events = item.events.length > 0 ? this.eventCalculStats(item.events) : 0;
                        return Math.round(events / this.reportDays);
                    }
                },
                {
                    key: 'monthly_events',
                    label: 'Monthly Events',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        const totalMonthlyEvents = item.monthly_events.length > 0 ? this.eventCalculStats(item.monthly_events) : 0;
                        return helper.formatDecimal(totalMonthlyEvents);
                    }
                },
                {
                    key: 'list_quality',
                    label: 'List Quality',
                    sortable: false,
                    class: 'text-center d-none',
                },
                {
                    key: 'current_plan',
                    label: 'Current Plan',
                    sortable: false,
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        let currentPlan = 'Free';
                        const subscriptions = item.account ? item.account.subscriptions : [];
                        if (subscriptions) {
                            const size = subscriptions.length;
                            currentPlan = subscriptions[size - 1]?.plan?.name ?? 'Free';
                        }

                        return currentPlan;
                    }
                },
                {
                    key: 'billing_date',
                    label: 'Billing Date',
                    sortable: false,
                    class: 'text-center tag d-none',
                    formatter: (value, key, item) => {
                        const size = item.account && (typeof item.account?.subscriptions !== 'undefined') ?  item.account.subscriptions.length : 0;
                        const nextBillingDate = size > 0 ? item.account.subscriptions[size - 1].next_billing_date : '--';
                        return nextBillingDate;
                    }
                },
                {
                    key: 'created_by',
                    label: 'Created by',
                    class: 'text-center d-none',
                    formatter: (value, key, item) => {
                        return !item.user ? '--' : item?.user?.name
                    },
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        var dt = new Date(item.created_at);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'active',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false,
                    class: 'text-center',
                    thStyle: {
                        width: "10%"
                    },
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'active',
            sortDesc: true,
            sortDirection: 'asc',
            links:[],
            filter: null,
            filterOn: ["name", "tag_name"],
            account: -1,
            from: null,
            to: null,
            dates: null,
            $search: '',
            reportDays: null,
            filterData: null,
            exportOptions: {},
            STATUS_SEND_MAIL: 102,
            STATUS_GET_GRAPH: 101,
            selected: [],
            selectAll: false,
          selectAllLoading:false,
            indeterminate: false,
            editModal: {
                id: 'edit-modal',
                title: '',
                item: {},
                row: {}
            },
            sendFlowModal: {
                id: 'send-flow-modal',
                title: '',
                item: {}
            },
            renderModalUpdate: false,
            showOrHideColumns: {
                selected: [],
                options: [],
                defaults: [
                    "selected",
                    "name",
                ],
            },
            renderModalLoAutomation: false,
            loAutomationModal: {
                id: 'link-automation',
                title: '',
                item: {}
            },
            bulkDailyLimitModal: {
                id: 'bulk-daily-limit',
                title: 'Daily Limit',
                items: []
            },
            showModalBulkDailyLimit: true,
            userSettings: null,
            nicknameIncicatorColor: 'liveopens_daily_limit_color',
            dailyLimitSetting: null,

        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters('Auth', ['user', 'isAdmin', 'isClient', 'UserSubscriptions', 'AccountServicePlans']),
    },
    mounted() {
        this.loadFields()
        this.renderModalUpdate = true;
        this.$root.$on('chartDone', (item) => {
            item.state = -101; // -1 for chart retrieved
        });

        // if (!this.isAdmin) {
        //     this.account = this.user.account_id;

        // }
        this.getUserSettings(this.user.id);


        let cols = _.map(_.compact(this.fields), (row) => {
            if (row.key && row.label && row.key != 'selected') {
                 return { name: row.label, code: row.key }
            }
        })
        this.showOrHideColumns.options = cols.slice(1);
    },
    methods: {
      limitedValues(columns) {
        if(columns.length>2){
          return  columns.slice(0, 1);
        }
        // Show all if showAll is true, otherwise show only the first three
        return columns.slice(0, 2);
      },
      openSchedulePopover(integration){
        this.$refs['popover-click-outside-' + integration.id].style.display= 'initial';
        this.$refs['popover-schedule-integration' + integration.id].$emit('open');
      },
      openListsPopover(integration){
        this.$refs['popover-click-outside-' + integration.id].style.display= 'initial';
        this.$refs['popover-lists-integration' + integration.id].$emit('open');
      },
      handelErrorSchedulePopover(integration){

        this.$bvToast.toast('failed to set the schedule', {
          title: 'failed to set the Schedule',
          variant: 'danger'
        });

      },
      finishSchedulePopover(integration,action){

        const index = this.$refs['dt-live-reports'].$data.localItems.findIndex((item) => item.id === integration.id);


        if (index !== -1) {

          this.$refs['dt-live-reports'].$data.localItems[index].disabled_at = integration.disabled_at;
          this.$refs['dt-live-reports'].$data.localItems[index].enabled_at = integration.enabled_at;
        }

        let title = '';
        let description = '';
        if(action === 'activate') {
          title = 'Activation scheduled successfully ';
          description = integration?.name  +' will be activated ' + integration.enabled_at;
        }else{
          title = 'Deactivation scheduled successfully ';
          description = integration?.name  +' will be deactivated ' + integration.disabled_at;
        }

        this.$bvToast.toast(description, {
          title: title,
          variant: 'success'
        });

        this.closePopover(integration);
      },
      closePopover(integration){
        if(this.$refs['popover-schedule-integration' + integration.id]){

          this.$refs['popover-click-outside-' + integration.id].style.display= 'none';
        this.$refs['popover-schedule-integration' + integration.id].$emit('close');
        }

        if(this.$refs['popover-lists-integration' + integration.id]){

          this.$refs['popover-click-outside-' + integration.id].style.display= 'none';
          this.$refs['popover-lists-integration' + integration.id].$emit('close');
        }
      },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        sumInArrayByKey(items = [], key = 'events_sent') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        eventCalculStats(array, key) {
            return this.sumInArrayByKey(array, key);
        },
        stringInArrayByKey(items = [], key = 'list_name') {
            let name = '';
            let i = 0;
            const length = items.length;
            items.forEach(item => {
                i++;
                name += i != length ? item[key] + ', ' : item[key];

            });
            return name;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            this.$root.$emit('onFetchData');
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                    from: this.dates ? this.dates.from : -1,
                    to: this.dates ? this.dates.to : -1,
                    accountid: this.account,
                    $search: this.search
                }

                if (this.filterData) {
                    queryParams.filters = this.filterData;
                }
            }
            this.exportOptions = queryParams;
            return api.stats.reports(queryParams)
                .then((response) => {
                    items = response.data.reports.data;
                    const reportDays = response.data.days;
                    this.totalRows = response.data.reports.total
                    this.currentPage = response.data.reports.current_page
                    this.links = response.data.reports.links
                    this.perPage = response.data.reports.per_page

                    this.reportDays = reportDays != 0 ? reportDays : 1;
                    this.from = response.data.reports.from
                    this.to = response.data.reports.to
                    items = _.map(items, (row) => {
                        if (this.selectAll) {
                            row.selected = true
                            let alreadyAdded = _.find(this.selected, function (o) { return row.id == o; });
                            if (!alreadyAdded) {
                                this.selected.push(row.id)
                            }
                        } else {
                            row.selected = false
                        }

                        if (row.limit !== -1) {
                            let yesterday = moment().subtract(1, "days").format('YYYY-MM-DD');
                            let dailyEventSent = _.find(row.cycle_events ,(o) => { return o.date == yesterday })
                            let limitIsReached = this.isLimitReached(row);

                            let showColorIndicator = this.getUserliveopensDailyLimitColor();
                            if(!limitIsReached && showColorIndicator) {
                                row._rowVariant = 'warning';
                            }
                        }
                        // Errors lists
                        if(row.validation_code != null  ) {
                            row._rowVariant = 'danger';
                        }


                        return row
                    })

                    this.items = items;
                    let user_columns = ['name', 'tag_name', 'platform', 'total_events','created_at', 'actions', 'active', "lists"];
                    if (!_.isEmpty(response.data.user_columns)) {
                        user_columns = response.data.user_columns;
                    }
                    this.showOrHideColumns.selected = user_columns;
                    return this.items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.indeterminate = false
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                        this.$emit('onFinishedFilter');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                if (![
                        "account_id",
                        "billing_date",
                        "events_cycle",
                        "current_plan",
                        "created_by",
                        "monthly_events",
                        "list_quality",
                        "referral_id",
                    ].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        setAccount(account) {
            this.account = account
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
        setDates(dates) {
            this.dates = dates
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
      scheduleToolTip(integration){

        if(integration.enabled_at != null){
          return 'Activation Scheduled at ' + integration.enabled_at;

        }

        if(integration.disabled_at != null){
          return 'Deactivation Scheduled at ' + integration.disabled_at;

        }

        return null;
      },
      getPlatformImage(platform) {
        try {
          const imgFileName = platform.toLowerCase();
          return require(`@/assets/images/platforms/${imgFileName}.png`);
        } catch (e) {
          return null;
        }
      },
        // Filter
        onFiltered(filteredItems) {
             this.selectAll = null
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        listQuality(item) {
            const contacts = this.sumInArrayByKey(item.lists, 'total_contacts');
            const events = this.eventCalculStats(item.cycle_events);
            const total = contacts == 0 ? 0 : events / contacts;
            return Math.round(total);
        },
        listQualityIndic(item) {
            const indicator = this.listQuality(item);
            if (indicator <= 50) return 'ri-arrow-down-fill text-danger';
            else if (50 < indicator < 70) return 'ri-arrow-down-fill text-warning'
            else return 'ri-arrow-up-fill text-success'
        },
        // ACTIONS

        getChart(item, index, button) {
            item.state = 101; // spinner true load data graph wip
            this.$root.$emit('showChart', item);
        },
        exportCSV(item, index, button) {

            const INTEGRATION_STATE = item.state;
            this.$root.$emit('hideChart', item);
            let params = {
                id: item.id,
                integration_name: item?.name,
                from: -1,
                to: -1
            };
            if (this.dates != null) {
                params.from = this.dates.from;
                params.to = this.dates.to;
            }
            item.state = this.STATUS_SEND_MAIL; // spinner send mail in progress
            api.stats.sendIntegrationReport(params).then(response => {

                this.$swal('Success', response.message, 'success')

            }).catch((err) => {
                 this.$swal('Error', err.response.data.message, 'error')
            }).finally(() => {
                item.state = INTEGRATION_STATE;
            })
        },
        filterTable(params) {
            this.filterData = JSON.stringify({
                status: params.status ?.code ?? '',
                daily_cap: params.daily_cap ?? '',
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? '',
                account_type: params.account_type?.code ?? '',
                plans: params.plans ? _.map(params.plans, 'code') : [],
                platform: params.platform ? _.map(params.platform, 'name') : [],
                platform_account: params.platform_account ?? '',
            });
            this.$root.$emit('bv::refresh::table', this.id)
        },
        hasChangeRowSelect($event, item) {

            if (!this.selected.includes(item.id) && $event.target.checked) {
                this.selected.push(item.id)
            } else {
                this.selected = _.remove(this.selected, function (n) {
                    return n !== item.id
                });
            }

            if (this.selected.length) {
                this.indeterminate = true;
            } else {
                this.indeterminate = false;
            }

        },
        isChecked(item) {

            if (this.selected.includes(item.id)) {
                item.selected = true
            } else {
                item.selected = false
            }

            return 'checked' ? this.selected.includes(item.id) : '';
        },
        handleSelectedAction(action = 'turn-on') {
            let showAlert = null;
            let checkShowAlert = null;

            if (!this.selected.length) {
                return
            }

            this.itemInOperate = true;
            const that = this;

            let bulkData = { active: -1 }

            let alertObject = {
                title: 'Are you sure?',
                text: `This action will permenantly delete ` + (this.selected.length ? ` ${(this.selected.length)} Integration${(this.selected.length > 1 ? 's' : '')}` : ''),
                icon: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#e64141',
                allowOutsideClick: () => !that.$swal.isLoading()
            }

            switch (action) {
                case 'turn-on':
                    checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

                    if (checkShowAlert.state && checkShowAlert.alert) {
                        showAlert = true;
                    }

                    alertObject.icon = 'success'
                    alertObject.text = `If your ` + `${(this.selected.length ?? '')}` + ` Integration${(this.selected.length > 1 ? 's' : 'Integration')}` + ` shares contacts with any other synced list on the InboxGeek platform, events will begin sending as soon as today.`
                    alertObject.confirmButtonText = 'Turn on'
                    alertObject.confirmButtonColor = '#00ca00'
                    bulkData = { active: 1 }
                    break

                case 'turn-off':
                    alertObject.icon = 'question'
                    alertObject.text = `If an integration is turned off for longer than 1:00a EST the following day, the contacts synced to that list will need 24 hours to re-sync once the integration is turned on again.`
                    alertObject.confirmButtonText = 'Turn off'
                    alertObject.confirmButtonColor = '#0084ff'
                    bulkData = { active: 0 }
                    break

                case 'delete':
                    break

                default:
                    break
            }

            let swalObj = {
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            alertObject.preConfirm = () => {

                that.$swal.update({
                    title: 'In Progress', text: 'Please wait, action in progress ...', showConfirmButton: false, allowOutsideClick: false, showCloseButton: false,
                })

                if (action == 'delete') {

                    return api.integrations.delete(that.selected[0], { items: that.selected })
                        .then(result => {
                            swalObj.title = 'Delete!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                }

                return api.integrations.put(that.selected[0], { items: that.selected, data: bulkData })
                    .then(result => {
                        swalObj.title = 'Success!';
                        swalObj.text = result.message;
                        this.indeterminate = false
                    })
                    .catch(function (error) {
                        swalObj.type = 'warning';
                        swalObj.title = 'Warning!';
                        swalObj.text = 'Something went wrong!';
                    })

            };

            const bulkConfirmAction = (obj) => {
                that.$swal(obj)
                    .then((result) => {
                        that.itemInOperate = false;

                        if (result.isConfirmed) {
                            that.selected = [];
                            that.selectAll = false;
                            that.$swal(swalObj)
                            that.$root.$emit('bv::refresh::table', that.id);
                        }
                    })
            }


            if(showAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        bulkConfirmAction(alertObject)
                    })
            } else {
                bulkConfirmAction(alertObject)
            }
        },
        handleBulkDailyLimitAction() {

            this.showModalBulkDailyLimit = false;
            let items = _.filter(this.$refs['dt-live-reports'].$data.localItems, (o) => { return this.selected.includes(o.id) });

            this.bulkDailyLimitModal.items = items;

            this.showModalBulkDailyLimit = true;
            this.$root.$emit('bv::show::modal', this.bulkDailyLimitModal.id)

        },
        openPayoutInputOnInput: function (event) {
            if (event.target.className.includes('open-payout-input')) {
                this.vueBind.model.payout.amount = event.target.value;
            }
        },
        // ACTIONS
        selectRow() {
            this.selected = []
            if (this.selectAll) {
                for (let i in this.items) {
                    this.selected.push(this.items[i].id);
                }
            } else {
                this.selected = []
            }
        },
        selectAllItems($event) {

        this.selectAllLoading = true;

            let links = _.filter(this.links, function (o) { console.log('o',o);  return o.url && !['Next &raquo;'].includes(o.label); });

          let queryParams = {
            search: this.filter,
            page: this.currentPage,
            per_page: this.perPage,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            filter_on: JSON.stringify(this.filterOn),
            from: this.dates ? this.dates.from : -1,
            to: this.dates ? this.dates.to : -1,
            accountid: this.account,
            $search: this.search
          }

          if (this.filterData) {
            queryParams.filters = this.filterData;
          }

            let allItemsPromises = _.map(links, (item) => {
                let page = item.url.split("?page=")[1]
                queryParams.page = page
                return api.stats.reports(queryParams)
            })
          this.selected = [];
            Promise.all(allItemsPromises).then((values) => {

                _.forEach(values,  (resp) => {
                    if (resp.success) {
                        _.forEach(resp.data.reports.data,  (row) => {

                            if (!this.selected.includes(row.id)) {
                                this.selected.push(row.id)
                            }
                        });
                    }
                });

                this.selectAll = true;
                this.indeterminate = false;
                this.selectAllLoading = false;
            });
        },
        clearSelection($event) {
            this.selectAll = false
            this.indeterminate = false;
            this.selected = []
        },
        onUpdateItem(data) {
            let showPreAlert = null;
            let checkShowAlert = null;
            let resultData = null;


            checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

            if (checkShowAlert.state && checkShowAlert.alert) {
                showPreAlert = true;
            }

            const updateRowState = (data) => {
                let itemCode = data.item.id
                let form = { active: data.item.active === 1 ? 0 : 1 }

                let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

                return api.integrations.put(itemCode, form)
                    .then(result => {
                        resultData = result.data
                        toast = { message: result?.message, title: 'Success', variant: 'success' }
                    })
                    .finally(() => {

                        const index = this.$refs['dt-live-reports'].$data.localItems.findIndex((item) => item.id === resultData.id);

                      this.$refs['dt-live-reports'].$data.localItems[index].disabled_at = null;
                      this.$refs['dt-live-reports'].$data.localItems[index].enabled_at = null;

                        if (index !== -1) {
                            const itemUpdated = { ...data.item, ...resultData }
                          this.$refs['dt-live-reports'].$data.localItems[index].active = form.active;
                          this.closePopover(this.$refs['dt-live-reports'].$data.localItems[index]);
                            //this.$set(, index, itemUpdated);
                        }
                        this.$root.$bvToast.toast(toast.message, toast);
                    });

            }


            if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        updateRowState(data)
                    })

            } else {

                updateRowState(data)

            }

        },
        showAlertBalance() {

            return this.isClient
                && (!this.details.remaining
                    || (this.details.limit != -1 && (this.details.remaining + this.details.limit <= 0))
                )

        },
        accountIsInactive() {
            return (this.isClient && !this.details.account.status)
        },
        showAlertsAccount() {
            if (this.accountIsInactive()) {
                helper.alertAccountInactive(this.$swal)
            } else if (this.showAlertBalance()) {
                this.showAlertRemaining()
            }
        },
        histories(item) {
            this.$root.$emit('hideChart', item);
            this.$emit('onShowInvoiceHistories', item);
        },
        removeItem(item, index, button) {
            this.$root.$emit('hideChart', item);
            this.$swal({
                title: 'Are you sure?',
                text: `This action will permenantly delete ${item?.name} integration.`,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        type: 'success',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                    }

                    api.integrations.delete(item.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = result.message;
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            this.$root.$emit('bv::refresh::table', this.id);
                        });
                }
            })
        },
        resetEditModal() {
            this.editModal.title = ''
            this.editModal.item = {}
            this.loading.update = false;
        },
        resetLoAutomationModal() {
            this.loAutomationModal.title = ''
            this.loAutomationModal.item = {};

            this.$root.$emit('bv::refresh::table', this.id);
        },
        editItem(row, button) {

            this.loading.update = true;
            this.editModal.title = `Edit integration ${row.item?.name}`
            this.editModal.item = row.item
            this.$root.$emit('hideChart', row.item);
            this.$root.$emit('bv::show::modal', this.editModal.id, button)

        },
        linkAutomation(item, index, button) {

            this.loAutomationModal.title = `Integration: ${item?.name} - Link to Automation`
            this.loAutomationModal.item = item
            // this.$root.$emit('hideChart', item);
            helper.delay(300).then(() => {
                this.$root.$emit('bv::show::modal', this.loAutomationModal.id, button)
            })

        },
        unlinkAutomation(item, index, button) {

            if(!item.id) { return; }

            api.loAutomations.unlink(item.id)
                .then((response) => {
                    if(response.success) {
                        this.$swal('', `<p>You’ve unlinked your InboxGeek integration from your <span class="font-weight-bold">${item.platform?.name}</span> automation.<br><span class="small font-weight-bold">You will no longer see your statistical reporting in InboxGeek for this automation</span><p>`, 'success')
                            .then((confirm) => {
                                this.$root.$emit('bv::refresh::table', this.id)
                            })
                    }
                })
                .catch((error) => {

                    if (error.response.status == 500) {
                        this.$bvToast.toast('something went wrong!', {
                            title: `Error Unlink Integration ${item?.name}`,
                            variant: 'danger'
                        });
                        return;
                    }

                    this.$bvToast.toast(error.response.data.errors.message, {
                        title: `Error Unlink Integration ${item?.name}`,
                        variant: 'danger'
                    })

                })
        },
        clientHasSubscriptionValid() {

            let service = 1;
            let serviceSubscription = typeof this.UserSubscriptions[service] != 'undefined' ? this.UserSubscriptions[service] : null
            let isValidSubscription = null;

            if (serviceSubscription && serviceSubscription.active) {
                let time = serviceSubscription.ends_at ? moment(serviceSubscription.ends_at)
                    : serviceSubscription && !serviceSubscription.ends_at;
                if (time !== true && time > moment() || time === true) isValidSubscription = true;


            }
            return this.isClient && isValidSubscription
        },
        showAlertRemaining() {
            let subscription = this.UserSubscriptions[1];

            let date = subscription ? subscription.ends_at : '--';

            let alertMessage = `It\'s appears you are out of events until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

            this.$swal({
                title: 'Uh, oh...',
                text: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        sendFlow(item, index, button) {

            this.sendFlowModal.item = item;
            this.$root.$emit('hideChart', item);
            this.$root.$emit('bv::show::modal', this.sendFlowModal.id, button)
        },
        onSearch() {
             this.$root.$emit('hideChart', []);
        },
        hideUpdateIntegrationModalide(options) {

            this.updateItem(options.data);

            setTimeout(() => {
                if (options.state) {
                    this.$root.$emit('bv::hide::modal', this.editModal.id)
                }
            }, 1500)
        },
        forceRerenderModalUpdate() {
            this.renderModalUpdate = false;
            this.$nextTick(() => {
                this.renderModalUpdate = true
            })
        },
        hideModalLoAutomation(success) {
            this.forceRerenderModalLoAutomation();
            setTimeout(() => {
                if (success) {
                    this.$root.$emit('bv::hide::modal', this.editModal.id)
                    this.$root.$emit('bv::refresh::table', this.id)
                }
            }, 2000)
        },
        forceRerenderModalLoAutomation() {
            this.renderModalLoAutomation = false;
            this.$nextTick(() => {
                this.renderModalLoAutomation = true
            })
        },
        onSelect(col) {
            col = _.map(col, 'code');
            col = [...col, ...this.showOrHideColumns.defaults];
            let fields = _.map(_.compact(this.fields), (item) => {
                if (col.includes(item.key)) {
                    item.class = "text-center";
                } else {
                    item.class = "text-center d-none";
                }
                return item;
            })
            this.fields = fields;
            this.savePreferenceColumns(col);
        },
        savePreferenceColumns(col) {

             return api.users.integration_columns(this.user.id, col)
                .then(result => {
                    console.log('preference saved')
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        isLimitReached(item) {
            let yesterday = moment().subtract(1, "days").format('YYYY-MM-DD');
            let dailyEventSent = _.find(item.cycle_events, (o) => { return o.date == yesterday })
            let limitIsReached = item.limit != -1 && dailyEventSent && (dailyEventSent.events_sent >= item.limit);

            return limitIsReached;
        },
        onRowHovered(item, _, event) {
            let showColorIndicator = this.getUserliveopensDailyLimitColor();

            if (item.limit != -1 && !this.isLimitReached(item) && showColorIndicator) {
                let lastLogMessage = 'Daily limit not reached';
                let options = {
                    title: lastLogMessage,
                    trigger: 'hover',
                }
                $(event.target).tooltip(options)
            }
        },
        integration_logs(integration) {
            let errors = [];
            // Lists logs
            let list = _.first(integration.lists);
            if (typeof list != 'undefined' && list) {
                let list_logs= list.tracked_lists_errors;
                if (typeof list_logs != 'undefined' && list_logs) {
                    let list_log = _.last(list_logs);
                    if (typeof list_log != 'undefined' && list_log) {
                        errors.push({ id: list_log.id, message: list_log.errors, created_at: list_log.created_at })
                     }

                }
            }
            let platform_logs = integration.platform?.logs;
            let platform_log = _.last(platform_logs);

            // Platform logs
            if(platform_log) {
                errors.push({id: platform_log.id, message: platform_log.message, created_at: platform_log.created_at})
            }
            // Template
            let ul = '<ol>'
             errors.forEach(( error ) => {
                let date = this.formatDate(error.created_at);
                let note = error.message + `(${date})`
                ul += `<li>${note}</li>`
            });

            return ul;
        },
        getUserSettings(userId) {
            const self = this;

            api.userSettings.getUserSettings(userId)
                .then((response) => {

                    if(response.success) {
                        this.userSettings = response.data;
                        const nickname = this.nicknameIncicatorColor
                        let dailyLimitIndicatorSetting = _.find(this.userSettings, {nickname: nickname});

                        if(dailyLimitIndicatorSetting) {
                            this.dailyLimitSetting = dailyLimitIndicatorSetting;
                        }
                    }

                })
        },
        getUserliveopensDailyLimitColor() {

            const nickname = this.nicknameIncicatorColor;
            let dailyLimitIndicatorSetting = _.find(this.userSettings, {nickname: nickname});

            if(dailyLimitIndicatorSetting) {
                this.dailyLimitSetting = dailyLimitIndicatorSetting;
            }

            return this.dailyLimitSetting ? parseInt(this.dailyLimitSetting.value) == 1 : false;
        },
        canLinkAutomation(item) {
            return item.platform && [
              'maropost',
              'mailchimp',
              'expertsender',
              'sendgrid',
              'getresponse',
              'activecampaign',
              'campaigner',
              'brevo',
              'inbox',
              'blastable',
              'sendlane',
              'listrak',
              'emailoctopus',
              'ongage',
              'hubspot',
            ].includes(item.platform?.name.toLowerCase())
        },
        isClientAndOutOfEvents() {
            return this.isClient && !helper.checkIfAccountSubscriptionByService(SERVICE_ID);
        },
        clientHasNotRemaining() {
            return this.isClient && (this.AccountServicePlans[SERVICE_ID] && this.AccountServicePlans[SERVICE_ID].limit != -1 && !this.AccountServicePlans[SERVICE_ID].remaining);
        },
        updateItem(resultData) {

            const index = this.$refs['dt-live-reports'].$data.localItems.findIndex((item) => item.id === resultData.id);

            if (index !== -1) {
                const itemUpdated = { ...this.editModal.item, ...resultData }
                this.$set(this.$refs['dt-live-reports'].$data.localItems, index, itemUpdated);
            }

            this.resetEditModal();

        },
        hideModalBulkDailyLimit(items) {

            _.forEach(items, (integration) => {

                let itemIntegration = null;
                const index = this.$refs['dt-live-reports'].$data.localItems.findIndex((item) => {
                    itemIntegration = item;
                    return item.id === integration.id;
                });

                if (index !== -1) {
                    const itemUpdated = { ...itemIntegration, ...integration }
                    this.$set(this.$refs['dt-live-reports'].$data.localItems, index, itemUpdated);
                }

            });

            this.clearSelection();
            this.$root.$emit('bv::hide::modal', this.bulkDailyLimitModal.id)

        },
        filterLessThan(value, max = 12) {
            return helper.showMaxCharacters(value, max)
        },
      updateDisabledIntegration(resultData,toast){


        const index = this.$refs['dt-live-reports'].$data.localItems.findIndex((item) => item.id === resultData.id);

        this.$refs['dt-live-reports'].$data.localItems[index].disabled_at = null;
        this.$refs['dt-live-reports'].$data.localItems[index].enabled_at = null;

        if (index !== -1) {

          this.$refs['dt-live-reports'].$data.localItems[index].active = 1;
          this.closePopover(this.$refs['dt-live-reports'].$data.localItems[index]);
          //this.$set(, index, itemUpdated);
        }
        this.$root.$bvToast.toast(toast.message, toast);
      }
    },
    watch: {
        "showOrHideColumns.selected": {
            handler(newVal, oldVal) {
                this.$emit('onFilterIntegrationColum', this.showOrHideColumns.options, newVal, this.showOrHideColumns.defaults)
            }
        }
    }
}
</script>

<style>
.table tbody tr:nth-of-type(odd) {
  background-color: rgb(155 188 196 / 11%) ;
}

.scheduled .custom-control-label::before{
  background-color: #fac483;
  color: #9d3a11;
  border-color: #d66027;
}

.scheduled .custom-control-label::after  {
  background-color: #fff7eb;
}

td.lists>div,
td.integration>div {
    width: 16em;
}

td.tag>div,
td.account>div {
    width: 14em;
}

.ig-text-wrap {
    word-wrap: break-word;
}

ul.dropdown-menu.show {
    display: inline-block !important;
}

.dropdown-toggle::after {
    display: none !important;
}

li a.dropdown-item,
a[target] {
    padding: 10px 5px;
    text-decoration: none;
}

.dots-animate {
    animation: shadow-pulse-dots 1s infinite;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.dropdown.b-dropdown {
    float: right !important;
}

.table th, .table td {
  padding:0.4rem 0.2rem !important;
  vertical-align: middle !important;
}
.table th:nth-child(1){
  min-width: 60px !important;
}
.table th{
  min-width: 130px !important;
}
.table-danger, .table-danger > th, .table-danger > td {
  background-color: #fce9eb !important;
}

/* -- switch - disabled integration because of Platform Error  -- */

.custom-switch.custom-switch-text.platform-error-disable label::before {
background-color: #9da0ad !important;
  color: white !important;
  cursor: pointer;
}
.custom-switch.custom-switch-text.platform-error-disable label::after {
  background-color: #b5b5b5 !important;
  box-shadow: 0px 1px 2px #4f4f4f70;
  cursor: pointer;
}
</style>
