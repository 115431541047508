<template>

<div class="w-100">




  <b-row class="my-2">
    <b-col>
      <label for="name">Account ID :</label>
      <b-input v-model="platform.account" placeholder="https://<youraccountname>.api-us1.com"></b-input>
    </b-col>
  </b-row>

  <div class="alert alert-info" v-show="!isValidAccount && platform.account != ''" style="    flex-direction: column;
    color: rgb(181 38 38);
    border-color: rgb(255 178 178 / 27%);
    background-color: rgb(255 229 229 / 26%);
    padding: 11px 10px;">

    <h6 class="alert-heading" style="margin: 0">Please verify that your account name is valid:</h6>
    <p  style="margin: 0"> <small>The account ID will be the name within the URL. </small></p>
  </div>


  <b-row class="my-2">
    <b-col>
      <label for="">API Key/Token :</label>
      <b-input v-model="platform.apikey" placeholder="Api key"></b-input>
    </b-col>
  </b-row>

  <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform"> <b-spinner v-if="isLoading" small type="grow"></b-spinner>  Create Platform</button>
      </b-col>
    </b-row>
  
  
  
  </div>
  
  
  </template>
  
  <script>

    import api from '@/api/RestClient'

    export default {
      name: 'ActiveCampaignInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
  
      },
      
      data(){
        return {
          isDisabled:false,
          isLoading:false,
          platform: {
            account:null,
            apikey:null
          },
          showNotes : {
            "account-alert" : false,
            "account-invalid" : false,
          }
        }
      },
      methods: {
        createPlatform(){
          this.isLoading = true;
          this.isDisabled = true;

          api.platforms
          .verifyToken('activecampaign',this.validatedAccountInput,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',{account : this.validatedAccountInput, apikey : this.platform.apikey});
            this.isLoading = false;
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)
            this.showNotes['account-invalid'] =true;
          });

         
        }
      },
        computed : {
            isAllowed(){
                return ( this.platform_nickname != null && this.platform_nickname.trim() !== '' ) && ! this.isDisabled ;
            },

          isValidAccount(){
            this.showNotes['account-invalid'] = false;
            try {
              const parsedUrl = new URL(this.platform.account);

              const parts = parsedUrl.hostname.split('.');

              if (parts.length > 2) {
                return true;
              } else {

                if (/^[a-zA-Z0-9-]+$/.test(this.platform.account)) {

                  return true;
                }

                // If invalid input
                return false;

              }
            } catch (error) {


              if (/^[a-zA-Z0-9-]+$/.test(this.platform.account)) {

                return true;
              }

              console.log('error', error);
             return false;

            }

          },
            validatedAccountInput(){
              this.showNotes['account-invalid'] = false;
              try {
                const parsedUrl = new URL(this.platform.account);

                const parts = parsedUrl.hostname.split('.');

                if (parts.length > 2) {
                  return parts[0];
                } else {

                if (/^[a-zA-Z0-9-]+$/.test(this.platform.account)) {

                  return this.platform.account; // Valid account name
                }

                // If invalid input
                this.showNotes['account-invalid'] = true;
                  return this.platform.account; // No subdomain found
                }
              } catch (error) {

                this.showNotes['account-invalid'] = true;
                return this.platform.account; // Invalid URL
              }
            }
        }
  
    }
  
  </script>